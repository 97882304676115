import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  {tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpClient) { }

  getItems(exhibitId: number): Observable<Object[]>{
    // console.log('api url is ' + environment.apiUrl);
    return this.http.get<Object[]>(environment.apiUrl + 'exhibit/' + exhibitId + '/items').pipe(
      tap(_ => console.log('fetched exhibit items')));

  }

  getItemAttributes(itemId: number): Observable<Object[]>{
    return this.http.get<Object[]>(environment.apiUrl + 'item/' + itemId + '/attribs').pipe(
      tap(_ => console.log('fetched exhibit items')));
  }
}
