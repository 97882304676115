import { Component, OnInit } from '@angular/core';
import {RoomService} from '../../services/room-service.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  title = 'Chronistichthyology (Clocks &amp; Fish)';
  rooms: Object[];

  constructor(private roomService: RoomService){}


  ngOnInit() {

     this.roomService.getAllRooms().subscribe((data: Object[]) => this.rooms = data);
  
  }

}
