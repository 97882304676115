import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from 'src/app/services/room-service.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-room-page',
  templateUrl: './room-page.component.html',
  styleUrls: ['./room-page.component.scss']
})
export class RoomPageComponent implements OnInit {
  name: string;
  exhibits: Object[];

  constructor(private route: ActivatedRoute, private roomService: RoomService) { 
    
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap(params => {
        this.name = params.get('name');
        return this.roomService.getExhibits(this.name);
      })

    ).subscribe(data => this.exhibits = data);
  }

}
