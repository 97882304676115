import { Component, OnInit } from '@angular/core';
import { RoomService } from './services/room-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  rooms: Object[];

  constructor(private roomService: RoomService){}
  
  ngOnInit(): void {
    this.roomService.getAllRooms().subscribe(data => this.rooms = data);
  }


}
