import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/item-service.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ExhibitService } from 'src/app/services/exhibit-service.service';
import { ModalDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-exhibit-page',
  templateUrl: './exhibit-page.component.html',
  styleUrls: ['./exhibit-page.component.scss']
})
export class ExhibitPageComponent implements OnInit {
  name: string;
  roomName: string;
  coverImage: string;
  private id: number;
  description: string;
  currentSlideIndex: number;
  currentItem: Object;
  displayItem: Object;
  items: Object[];
  // imgModal: ModalDirective;

  constructor(private route: ActivatedRoute, private itemService: ItemService, private exhibitService: ExhibitService) { 
    this.currentItem = {};
    this.displayItem = {};
  }

  onSlideChange(slideEvent: any){
    this.currentSlideIndex = slideEvent.relatedTarget;
    this.currentItem = this.items[this.currentSlideIndex];
  }

  showItemCoverImage(item: Object){
    this.displayItem = item;
    if(!item.hasOwnProperty('image_url')){
      this.displayItem = {
        'name': item['name'],
        'image_url': item['value']
      };
    }
    // this.imgModal.show();
    
  }

  onImgModalOpened(event: any){
    
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params =>
        {
          this.id = +params.get('id');
          this.roomName =  params.get('name');
        }
      );
    
    this.route.queryParamMap.pipe(
      switchMap(queryParams => {
        this.coverImage = queryParams.get('cover');
        this.name = queryParams.get('exhibitName');
        
        if(!this.coverImage || !this.name){
          this.exhibitService.getExhibitById(this.id).subscribe(data => {
            this.coverImage = data['image_url'];
            this.name = data['name'];
          });
        }
        return this.itemService.getItems(this.id);
      })

    ).subscribe(data => {
      this.items = data;
      if(data.length > 0){
        this.currentItem = data[0];
        this.description = this.items[0]['exhibit']['description'];
      }
    });
  }

}
