import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'exhibit-thumb',
  templateUrl: './exhibit-thumb.component.html',
  styleUrls: ['./exhibit-thumb.component.scss']
})
export class ExhibitThumbComponent implements OnInit {
  @Input() exhibit: Object;
  @Input() roomName: String;
  constructor(private router: Router) { }

  ngOnInit() {
    if(this.exhibit['image_url'] != null){

      this.exhibit['image_url'] = this.exhibit['image_url'].replace('open?', 'uc?');
    }
  }

  goToExhibit(){
    this.router.navigate(
      ['/room/' + this.roomName + '/exhibit/' + this.exhibit['id']], 
      {queryParams:
        {
          'cover': this.exhibit['image_url'],
          'exhibitName': this.exhibit['name']
      }
      });
  }

}
