import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ExhibitThumbComponent } from './components/exhibit-thumb/exhibit-thumb.component';
import { RoomPageComponent } from './components/room-page/room-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ExhibitPageComponent } from './components/exhibit-page/exhibit-page.component';
import { ItemThumbComponent } from './components/item-thumb/item-thumb.component';

@NgModule({
  declarations: [
    AppComponent,
    ExhibitThumbComponent,
    RoomPageComponent,
    MainPageComponent,
    ExhibitPageComponent,
    ItemThumbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
