import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'item-thumb',
  templateUrl: './item-thumb.component.html',
  styleUrls: ['./item-thumb.component.scss']
})
export class ItemThumbComponent implements OnInit {
  @Input() item: Object;
  constructor() { }

  ngOnInit() {
    if(this.item['image_url'] != null){

      this.item['image_url'] = this.item['image_url'].replace('open?', 'uc?');
    }
  }

}
